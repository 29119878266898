import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import theme from "assets/theme";
import { Alert, Box, Loader } from "components";
import Auths from "containers/Auths";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import routes, { RequireAuth } from "routes/routes";
import cookiesManipulator from "services/browserStorage/cookies";
import useOnlineStatus from "services/hooks/useOnlineStatus";
import { useLazyGetSurveyQuery } from "store/services/surveysApi";

function App() {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [appTheme, setAppTheme] = useState(theme);
  const location = useLocation();
  const { isOnline, resetIsOnline } = useOnlineStatus();
  const navigate = useNavigate();
  const surveyToken = cookiesManipulator.getCookies("survey_token");
  const sessionToken = cookiesManipulator.getCookies("token");

  const [getSurvey, surveyStats] = useLazyGetSurveyQuery();

  useEffect(() => {
    const fetchSurvey = async () => {
      const isUserAuthenticated = !!sessionToken;
      const hasSurveyToken = !!surveyToken;
      if (isUserAuthenticated && hasSurveyToken) {
        await getSurvey(surveyToken, true);
      }
    };
    fetchSurvey();
  }, [sessionToken, surveyToken]);

  useEffect(() => {
    setInnerHeight(window.innerHeight);
  }, [window.innerHeight]);

  useEffect(() => {
    if (surveyStats.status === "fulfilled") {
      const [survey] = surveyStats.data.surveys;
      if (survey.theme_id !== null) {
        const surveyTheme = survey.theme.theme;

        const appThemeTemp = { ...appTheme };
        appThemeTemp.palette = {
          ...appThemeTemp.palette,
          background: surveyTheme.background,
          primary: surveyTheme.primary,
          secondary: surveyTheme.secondary,
          text: surveyTheme.text,
          gradients: {
            ...appThemeTemp.palette.gradients,
            primary: { main: surveyTheme.primary.main, state: surveyTheme.primary.focus },
            secondary: { main: surveyTheme.secondary.main, state: surveyTheme.secondary.focus },
          },
        };
        setAppTheme(appThemeTemp);
      }
    }
  }, [surveyStats.status]);

  const handleRefresh = () => {
    navigate(0);
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.layout + route.route}
            element={
              route.layout === "auth" ? (
                route.component
              ) : (
                <RequireAuth>{route.component}</RequireAuth>
              )
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={createTheme(appTheme)}>
      <Loader toggle={surveyStats.status === "pending"} />
      <Alert
        alertType="error"
        toggle={surveyStats.isError}
        handleCloseError={handleRefresh}
        duration={0}
      >
        {surveyStats.isError ? surveyStats.error.message : ""}
      </Alert>

      <Box sx={({ palette }) => ({ background: palette.background.main, height: innerHeight })}>
        <CssBaseline />
        {isOnline === true && isOnline !== null ? (
          <Alert
            toggle={isOnline === true && isOnline !== null}
            alertType="success"
            duration={2000}
            handleCloseError={() => {
              resetIsOnline();
            }}
          >
            Online
          </Alert>
        ) : null}
        {isOnline === false && isOnline !== null ? (
          <Alert duration={0} hideCloseButton>
            Offline
          </Alert>
        ) : null}
        <Routes>
          {getRoutes(routes)}
          <Route
            path="/auths"
            element={
              cookiesManipulator.getAuth().token && !location.search.split("=")[1] ? (
                <Navigate to="/welcome" />
              ) : (
                <Auths />
              )
            }
          />

          <Route path="*" element={<Navigate to="/auths" />} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
}
export default App;
