import { api } from "config/apiHandler";
import cookies from "services/browserStorage/cookies";

const auths = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (requestBody) => ({
        url: "/auths/session",
        method: "POST",
        body: requestBody,
      }),
      transformResponse: (baseQueryReturnValue, _, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          cookies.setAuth({ ...baseQueryReturnValue.data, survey_token: arg.survey_token });

          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
    logout: build.mutation({
      query: () => ({
        url: "/auths/logout",
        method: "PUT",
      }),
      transformResponse: async (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          await cookies.removeAuth();
          return baseQueryReturnValue.data;
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLoginMutation, useLogoutMutation } = auths;
