import { CircularProgress, LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import * as React from "react";

export default function Loader({ toggle, type }) {
  const [showLoader, setShowLoader] = React.useState(true);

  const progressBar = {
    linear: <LinearProgress color="secondary" />,
    circular: <CircularProgress color="secondary" />,
  };

  React.useEffect(() => {
    setShowLoader(toggle);
  }, [toggle]);

  return showLoader ? <Box sx={{ width: "100%" }}>{progressBar[type]}</Box> : null;
}

Loader.defaultProps = {
  type: "linear",
  toggle: true,
};

Loader.propTypes = {
  type: PropTypes.oneOf(["linear", "circular"]),
  toggle: PropTypes.bool,
};
